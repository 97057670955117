// TODO: add args to each of these.
import React, { useState } from "react";
import { Section, SubSection } from "~components/text-helpers";
import Latex from "react-latex";
import { graphql, Link } from "gatsby";
import Img, { FixedObject, FluidObject } from "gatsby-image";
import { css } from "@emotion/react";
import { Table } from "antd";
import { UnityAPI } from "~components/api-data";

import {
  Code,
  Response,
  Attribute,
  APIMethod,
  IL,
  VSpace,
  SideBySideCode,
  Warning,
  APIReferenceiTHOR,
} from "~components/documentation";

function ObjectID(props: { hashPrefix: string; required?: boolean }) {
  return (
    <Attribute
      hashPrefix={props.hashPrefix}
      name="objectId"
      required={true}
      type="str"
      description={
        <>
          The target object's <IL>objectId</IL>, found in the object's metadata.
        </>
      }
    />
  );
}

// TODO: rewrite
function ForceAction(props: { hashPrefix: string }) {
  return (
    <Attribute
      hashPrefix={props.hashPrefix}
      name="forceAction"
      default="False"
      type="bool"
      description={
        <>
          Set to <IL>True</IL> to allow an object to be picked up regardless of
          if it is within the <IL>visibilityDistance</IL> of the agent.
        </>
      }
    />
  );
}

function X(props: { hashPrefix: string; required?: boolean }) {
  return (
    <Attribute
      hashPrefix={props.hashPrefix}
      name="x"
      type="float"
      required={props.required === true}
      description={
        <p>
          Used instead of <IL>objectId</IL> as an alternate way to target an
          object based on the last image frame. Valid values are in{" "}
          <Latex>$$[0:1]$$</Latex>, corresponding to how far left the image is
          from the previous frame.
          {/* include example */}
        </p>
      }
    />
  );
}

function Y(props: { hashPrefix: string; required?: boolean }) {
  return (
    <Attribute
      hashPrefix={props.hashPrefix}
      name="y"
      type="float"
      required={props.required === true}
      description={
        <p>
          Used in tandem with <IL>x</IL> to target an object based on the last
          image frame, rather than an <IL>objectId</IL>. Valid values are in{" "}
          <Latex>$$[0:1]$$</Latex>, corresponding to how from the top the object
          is from the previous frame.
          {/* include example */}
        </p>
      }
    />
  );
}

export default function iTHORDocReference({ data, location }) {
  const [highlightedParam, setHighlightedParam] = useState("");
  return (
    <APIReferenceiTHOR columnKey="moving-objects" pageName="Object Movement">
      <Section sectionTitle="Held Objects" emoji="punch">
        <SubSection sectionTitle="Pickup Object" emoji="hatching_chick">
          <APIMethod
            location={location}
            methodKey="PickupObject"
            parentAPI={UnityAPI.PhysicsAgent}
            setHighlightedParam={setHighlightedParam}
            highlightedParam={highlightedParam}
          />
        </SubSection>
        <SubSection sectionTitle="Put Object" emoji="wastebasket">
          <APIMethod
            location={location}
            methodKey="PutObject"
            parentAPI={UnityAPI.PhysicsAgent}
            setHighlightedParam={setHighlightedParam}
            highlightedParam={highlightedParam}
          />
        </SubSection>
        {/* TODO: rename DropHandObject to DropObject. */}
        <SubSection sectionTitle="Drop Object" emoji="basketball">
          <APIMethod
            location={location}
            methodKey="DropObject"
            parentAPI={UnityAPI.PhysicsAgent}
            setHighlightedParam={setHighlightedParam}
            highlightedParam={highlightedParam}
          />
        </SubSection>
        <SubSection sectionTitle="Throw Object" emoji="baseball">
          <APIMethod
            location={location}
            methodKey="ThrowObject"
            parentAPI={UnityAPI.PhysicsAgent}
            setHighlightedParam={setHighlightedParam}
            highlightedParam={highlightedParam}
          />
        </SubSection>
        <SubSection sectionTitle="Move Held Object" emoji="wave">
          <APIMethod
            location={location}
            methodKey="MoveHeldObject"
            parentAPI={UnityAPI.PhysicsAgent}
            setHighlightedParam={setHighlightedParam}
            highlightedParam={highlightedParam}
          />
          <APIMethod
            location={location}
            methodKey="MoveHeldObject2"
            parentAPI={UnityAPI.PhysicsAgent}
            setHighlightedParam={setHighlightedParam}
            highlightedParam={highlightedParam}
          />
        </SubSection>
        <SubSection sectionTitle="Rotate Held Object" emoji="point_right">
          <APIMethod
            location={location}
            methodKey="RotateHeldObject"
            parentAPI={UnityAPI.PhysicsAgent}
            setHighlightedParam={setHighlightedParam}
            highlightedParam={highlightedParam}
          />
          <APIMethod
            location={location}
            methodKey="RotateHeldObject2"
            parentAPI={UnityAPI.PhysicsAgent}
            setHighlightedParam={setHighlightedParam}
            highlightedParam={highlightedParam}
          />
        </SubSection>
      </Section>
      <Section sectionTitle="Pushing Objects" emoji="oncoming_automobile">
        <SubSection
          sectionTitle="Directional Push"
          emoji="table_tennis_paddle_and_ball"
        >
          <p>
            <IL>DirectionalPush</IL> attempts to push an object in a given
            direction. Since objects can have different mass properties, pushing
            different objects often requires differing amounts of force to move
            them the same distance. Only <IL>pickupable</IL> and{" "}
            <IL>moveable</IL> objects can be pushed.
          </p>
          <SideBySideCode
            example={
              <>
                <Code
                  lines={[
                    "controller.step",
                    '    action="DirectionalPush",',
                    '    objectId="Sofa|3|2|1",',
                    '    moveMagnitude="100",',
                    '    pushAngle="90"',
                    ")",
                  ]}
                />
              </>
            }
            argsTitle="Directional Push Parameters"
          >
            <Attribute
              hashPrefix="directional-push"
              required={true}
              name="moveMagnitude"
              type="float"
              description={
                <p>
                  The amount of force used to pull the object in newtons.
                  Following natural physics, objects of different masses may
                  move different distances with the same amount of force.
                </p>
              }
            />
            <Attribute
              hashPrefix="directional-push"
              required={true}
              name="pushAngle"
              type="float"
              description={
                <p>
                  <Latex>
                    The direction vector to push the object. Values in $[0:360]$
                    are valid, with $0$ being the current forward direction of
                    the agent. This value will change the push direction
                    clockwise from the agent’s forward. (i.e., $90$ will push
                    the object directly right, a value of $180$ will push the
                    object backwards).
                  </Latex>
                </p>
              }
            />
            <ObjectID hashPrefix="directional-push" />
            <ForceAction hashPrefix="directional-push" />
          </SideBySideCode>
        </SubSection>
        <SubSection sectionTitle="Push Object" emoji="bowling">
          <p>
            <IL>PushObject</IL> is syntactic sugar for <IL>DirectionalPush</IL>{" "}
            with a <IL>pushAngle</IL> of <Latex>$0$</Latex>.
          </p>
          <SideBySideCode
            example={
              <>
                <Code
                  lines={[
                    "controller.step(",
                    '    action="PushObject",',
                    '    objectId="Mug|0.25|-0.27",',
                    "    forceAction=False",
                    ")",
                  ]}
                />
              </>
            }
            argsTitle="Push Object Parameters"
          >
            <Attribute
              hashPrefix="push"
              name="moveMagnitude"
              required={true}
              type="float"
              description={
                <p>
                  The amount of force used to push the object in newtons. Note
                  that objects of different masses will move different distances
                  if this magnitude is not changed.
                </p>
              }
            />
            <ObjectID hashPrefix="push" />
            <ForceAction hashPrefix="push" />
          </SideBySideCode>
        </SubSection>
        <SubSection sectionTitle="Pull Object" emoji="bow_and_arrow">
          <p>
            <IL>PullObject</IL> is syntactic sugar for <IL>DirectionalPush</IL>{" "}
            with a <IL>pushAngle</IL> of <Latex>$180$</Latex>.
          </p>
          <SideBySideCode
            example={
              <>
                <Code
                  lines={[
                    "controller.step(",
                    '    action="PullObject",',
                    '    objectId="Mug|0.25|-0.27",',
                    "    forceAction=False",
                    ")",
                  ]}
                />
              </>
            }
            argsTitle="Push Object Parameters"
          >
            <Attribute
              hashPrefix="push"
              name="moveMagnitude"
              required={true}
              type="float"
              description={
                <p>
                  The amount of force used to pull the object in newtons. Note
                  that objects of different masses will move different distances
                  if this magnitude is not changed.
                </p>
              }
            />
            <ObjectID hashPrefix="push" />
            <ForceAction hashPrefix="push" />
          </SideBySideCode>
        </SubSection>
        <SubSection sectionTitle="Touch Then Apply Force" emoji="hand">
          <p>
            <IL>TouchThenApplyForce</IL> allows the agent to push an object at a
            certain point on the object, in a given direction. If a{" "}
            <IL>pickupable</IL> or <IL>moveable</IL> sim object is hit along the
            path of this ray, it will have a force determined by{" "}
            <IL>moveMagnitude</IL> applied to it instantaneously. This action
            will return feedback in the <IL>actionReturn</IL> attribute of the
            event metadata return.
          </p>
          <SideBySideCode
            example={[
              "event = controller.step(",
              '    action="TouchThenApplyForce",',
              "    x=0.5,",
              "    y=0.5,",
              "    direction={",
              '        "x": 0.0,',
              '        "y": 1.0,',
              '        "z": 0.0',
              "    },",
              "    moveMagnitude=80,",
              "    handDistance=1.5",
              ")",
            ]}
            argsTitle="Parameters"
          >
            <X hashPrefix="touch-then-apply-force" required={true} />
            <Y hashPrefix="touch-then-apply-force" required={true} />
            <Attribute
              hashPrefix="touch-then-apply-force"
              name="direction"
              type="dict[str, float]"
              required={true}
              description={
                <p>
                  The direction vector relative to the agent’s current forward
                  to push any object touched.
                </p>
              }
            />
            <Attribute
              hashPrefix="touch-then-apply-force"
              name="moveMagnitude"
              type="float"
              required={true}
              description={
                <p>
                  The amount of force to apply to a touched object in newtons.
                </p>
              }
            />
            <Attribute
              hashPrefix="touch-then-apply-force"
              name="handDistance"
              type="float"
              required={true}
              description={
                <p>
                  The maximum Euclidean distance from the agent's camera that
                  the (x, y) point that targets the object can be, in meters. If
                  the point on the object is further away than{" "}
                  <IL>handDistance</IL>, the action will fail.
                </p>
              }
            />
          </SideBySideCode>
          <div
            css={css`
              margin-top: 45px;
            `}
          />
          <SideBySideCode
            example={
              <Response
                lines={[
                  "{",
                  '    "didHandTouchSomething": True',
                  '    "objectId": "Apple|+1|+1|+1"',
                  '    "armsLength": 1.20',
                  "}",
                ]}
              />
            }
            argsTitle='event.metadata["actionReturn"]'
          >
            <Attribute
              hashPrefix="touch-then-apply-force"
              name="didHandTouchSomething"
              type="bool"
              description={
                <p>
                  Returns <IL>True</IL> if a sim object was touched within the
                  ray’s length of <IL>handDistance</IL> meters.
                </p>
              }
            />
            <Attribute
              hashPrefix="touch-then-apply-force"
              name="objectId"
              type="str"
              description={
                <p>
                  The unique string id of the object touched by the ray. If the
                  object touched in the scene has collision but is not a sim
                  object, this <IL>objectId</IL> attribute will be "not a sim
                  object, a structure was touched".
                </p>
              }
            />
            <Attribute
              hashPrefix="touch-then-apply-force"
              name="armsLength"
              type="float"
              description={
                <p>
                  The distance from the touched point on the object to the
                  agent’s camera. This distance will never exceed the
                  handDistance value passed in originally unless the action
                  finishes as a failed action (see below).
                </p>
              }
            />
          </SideBySideCode>
          <p>
            This action will return a failure
            (event.metadata["lastActionSuccess"] == False) only in two cases:
          </p>
          <ol>
            <li>
              The raycast hit an object, but the object was outside of the
              maximum visibility range of the agent (ie: a handDistance of 10 is
              passed in, and hits an object 9 meters away, but the agent’s max
              visibility distance is 1.5 meters, causing a failure). The
              feedback object generated in this case will be
              didHandTouchSomething=False, objectId="", armsLength=handDistance.
            </li>
            <li>
              The handDistance of the action is larger than the agent’s max
              visibility distance and no object was hit. The feedback object
              generated in this case will be didHandTouchSomething=False,
              objectId="", armsLength=handDistance with a metadata errormessage
              of “the position the hand would have moved to is outside the
              agent’s max interaction range”.
            </li>
          </ol>
          {/* TODO: remove */}
          <p>
            Note that this action interacts with the visibility of an object in
            order to determine what can be poked. The visibility of an object is
            defined by:
          </p>
          <ol>
            <li>An object must be within the agent camera’s field of view.</li>
            <li>
              An object must be within the area of a cylinder defined by a
              radius of length visibilityDistance around the agent’s vertical y
              axis.
            </li>
            <li>
              If that object is within the cylinder, a line must be able to be
              cast from the agent’s camera position to a point on that object
              unobstructed.
            </li>
          </ol>
          <p>
            Because visibility is defined by the cylinder with radius
            visibilityDistance, the total area of objects that are touchable by
            this action is the intersection of the sphere of radius handDistance
            centered around the agent’s camera, and the cylinder of radius
            visibilityDistance about the agent’s vertical axis. Note that the
            agent camera is also centered around the agent’s vertical axis.
          </p>
        </SubSection>
        {/* TODO: is this actually a circle? */}
        {/* <SubSection sectionTitle="Spawn Target Circle" emoji="circus_tent">
          <p>
            Spawns a TargetCircle object on top of a receptacle in the scene,
            which enables:
          </p>
          <ul>
            <li>
              Applying actions to all objects inside of the circle at once,
              using actions like Push, Pull, or DirectionalPush, where the
              TargetCircle’s ID is passed to each action.
            </li>
            <li>
              Returning objects positions inside of the TargetCircle, from it’s
              receptacleObjectIds property in the object’s metadata.
            </li>
            <li>Having the TargetCircle never colliding with other objects.</li>
          </ul>
        </SubSection> */}
      </Section>
      <Section sectionTitle="Teleporting Objects" emoji="telescope">
        <SubSection
          sectionTitle="Place Object At Point"
          emoji="information_desk_person"
        >
          <p>
            <IL>PlaceObjectAtPoint</IL> attempts to place an object flush with
            the surface of a receptacle. This can only be used on objects that
            are <IL>pickupable</IL> or <IL>moveable</IL>. The point to place an
            object at can be generated by the{" "}
            <IL>GetSpawnCoordinatesAboveReceptacle</IL> action below. Combine
            these two actions in order to teleport objects onto different
            receptacle surfaces.
          </p>
          <SideBySideCode
            example={[
              "controller.step(",
              '    action="PlaceObjectAtPoint",',
              '    objectId="Toaster|1|1|1",',
              "    position={",
              '        "x": -1.25,',
              '        "y": 1.0,',
              '        "z": -1.0',
              "    }",
              ")",
            ]}
            argsTitle="Place Object At Point Parameters"
          >
            <ObjectID hashPrefix="place-object-at-point" required={true} />
            <Attribute
              hashPrefix="place-object-at-point"
              name="position"
              type="dict[str, float]"
              required={true}
              description={
                <p>
                  <Latex>
                    Global $$(x, y, z)$$ coordinates for the point to try and
                    place the object.
                  </Latex>
                </p>
              }
            />
          </SideBySideCode>
        </SubSection>
        <SubSection
          sectionTitle="Get Spawn Coordinate Above Receptacle"
          emoji="octopus"
        >
          <p>
            <IL>GetSpawnCoordinatesAboveReceptacle</IL> is to explicitly be used
            in tandem with the <IL>PlaceObjectAtPoint</IL> action. It returns an
            array of xyz dictionary representing the{" "}
            <Latex>$$(x, y, z)$$</Latex> coordinates of valid spawn positions
            above a <IL>receptacle</IL> object. The array of xyz dictionary is
            returned in the actionReturn metadata.
          </p>
          <SideBySideCode
            example={[
              "controller.step(",
              '    action="GetSpawnCoordinatesAboveReceptacle",',
              '    objectId="CounterTop|1|1|1",',
              "    anywhere=False",
              ")",
            ]}
            argsTitle="Parameters"
          >
            <ObjectID required={true} hashPrefix="spawn-coordinates" />
            <Attribute
              hashPrefix="spawn-coordinates"
              name="anywhere"
              type="bool"
              default="False"
              description={
                <p>
                  If <IL>True</IL>, spawn coordinates will be returned even if
                  the exact position of the coordinate is outside of the agent’s
                  field of view. Keep False to return spawn coordinates even if
                  they are not in view of the agent.
                </p>
              }
            />
          </SideBySideCode>
        </SubSection>
      </Section>
    </APIReferenceiTHOR>
  );
}
